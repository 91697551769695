import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { SiteContext } from "../context";

const ProfileDisplay = ({ user, handleUpdate }) => {
  const { apiUrl } = useContext(SiteContext);

  return (
    <div>
      <div className="container">
        <h3 className="my-2">
          Profile{" "}
          <span className="px-2">
            <button
              className="btn btn-primary"
              onClick={() => handleUpdate(user)}
            >
              <i className="bi bi-pencil-square text-dark"></i>
            </button>
          </span>
        </h3>

        <div className="container p-3">
          <div className="row row my-1">
            <div className="col-md-3 col-md-3 p-2">First Name</div>
            <div className="col-md-9 col-md-3 p-2">{user.firstname}</div>
          </div>
          <div className="row row my-1">
            <div className="col-md-3 col-md-3 p-2">Last Name</div>
            <div className="col-md-9 col-md-3 p-2">{user.lastname}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Username</div>
            <div className="col-md-9 p-2">{user.username}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Phone</div>
            <div className="col-md-9 p-2">{user.phone}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Email</div>
            <div className="col-md-9 p-2">{user.email}</div>
          </div>
          <div className="row my-1">
            <div className="col-md-3 p-2">Address</div>
            <div className="col-md-9 p-2">{user.address}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDisplay;
