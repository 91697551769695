import React, { useState, useContext } from "react";
import { SiteContext } from "../context";
import ReactModal from "react-modal";

const TableJobCat = ({ items, onDelete, handleUpdate }) => {
  const { apiUrl, isMobile } = useContext(SiteContext);
  const [showModal, setShowModal] = useState(false);
  const [itemSelected, setItemSelected] = useState("");

  const handleDelete = (id) => {
    setItemSelected(id);
    setShowModal(true);
  };

  const doDelete = (id) => {
    onDelete(id);
    setItemSelected("");
    setShowModal(false);
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? "25%" : "20%",
            bottom: isMobile ? "25%" : "30%",
            left: isMobile ? "2%" : "25%",
            right: isMobile ? "2%" : "25%",
            width: isMobile ? "96%" : "50%",
            height: isMobile ? "50%" : "50%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span
            className="btn btn-primary"
            onClick={() => doDelete(itemSelected)}
          >
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>

      <div className="container p-3">
        <div className="row border bg-light p-2">
          <div className="col-1">No</div>
          <div className="col-8">Name</div>
          <div className="col-3">Action</div>
        </div>
        {items.map((item, i) => {
          return (
            <div key={i} className="row border-bottom p-2">
              <div className="col-1">{++i}</div>
              <div className="col-8">
                {item.jcat}
                <br /> (Slug: {item.jcatslug})
              </div>
              <div className="col-3">
                <a onClick={() => handleUpdate(item)}>
                  <i className="bi bi-pencil-square text-dark h4"></i>
                </a>{" "}
                <a onClick={() => handleDelete(item.jcatid)}>
                  <i className="bi bi-trash text-danger h4"></i>
                </a>{" "}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableJobCat;
