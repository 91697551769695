import React from "react";
import { Header, Footer } from "./";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <div className="container py-3">About LuchukNG</div>
      <Footer />
    </div>
  );
};

export default AboutUs;
