import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import { ImageDisplay, NavigationBar } from "../components";
import moment from "moment";
import { formatViews } from "../utils";
import Seo from "./Seo";
import { InlineShareButtons } from "sharethis-reactjs";

const JobDetail = () => {
  const {
    userData,
    apiUrl,
    siteconfig,
    siteMaxWidth,
    allJobs,
    isLoggedOn,
    categories,
    pages,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const { title } = useParams();

  const navigate = useNavigate();

  const [animationPhotos, setAnimationPhotos] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState({});
  const [storyArr, setStoryArr] = useState([]);
  const [story, setStory] = useState({});
  const [filteredOne, setFilteredOne] = useState([]);
  const [filteredFour, setFilteredFour] = useState([]);
  const [homeServices, setHomeServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    handleCurrentPage("jobs");
    getJobs();
    window.scrollTo(0, 0);
  }, [title]);

  const getJobs = () => {
    setLoading(true);

    fetch(`${apiUrl}/api/jobs.php?action=fetch_jobs`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          let filtered = res.rows.filter(
            (item) => item.job_titleslug === title
          );
          setJob(filtered[0]);
          setStory(filtered[0]);
          setJobs(res.rows);
          setLoading(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitaliseText = (text) => {
    if (text === "") {
      return text;
    }
    let firstString = text.charAt(0);
    return firstString.toUpperCase() + "" + text.slice(1);
  };

  const doSanitize = (item) => {
    if (item === "" || item === undefined) {
      return item;
    }
    return item.replace(/<p>/g, "").replace(/<\/p>/g, " ");
  };

  const handleUpdate = (news) => {
    navigate("/updatejob", {
      state: { job: job, action: "update" },
    });
  };

  const getUrl = (url) => {
    if (isMobile) {
      let urlSplit = url.split("/");

      return `${apiUrl}/${urlSplit[0]}/${urlSplit[1]}/${urlSplit[2]}/${urlSplit[3]}/thumbnail/${urlSplit[4]}`;
    } else {
      return `${apiUrl}/${url}`;
    }
  };

  return (
    <div>
      <Seo
        title={
          job.hasOwnProperty("jobid")
            ? `${job.job_title} | ${siteconfig.shopname}`
            : `${siteconfig.shopname}`
        }
        description={job.hasOwnProperty("jobid") && job.job_description}
        url={`${apiUrl}/api/job_detail.php?id=${story.jobid}&title=${story.job_titleslug}`}
        img={
          job.hasOwnProperty("jobid") && job.job_photo.includes("images/jobs")
            ? `${apiUrl}/${job.job_photo}`
            : job.job_photo
        }
      />
      <Header />
      <div style={{ maxWidth: siteMaxWidth }} className="container">
        <NavigationBar section={"Jobs"} goback={false} />

        {loading && (
          <div className="d-flex justify-content-center align-content-center my-5">
            <div class="spinner-border text-secondary" role="status" />
          </div>
        )}

        {errorMsg !== "" && <div className="container">{errorMsg}</div>}

        <div className="container py-3 my-1">
          <div className="row">
            <div className="container col-md-8 p-2">
              <h1 className="py-3 my-2">{job.job_title}</h1>
              {story.hasOwnProperty("jobid") && (
                <InlineShareButtons
                  config={{
                    alignment: "center", // alignment of buttons (left, center, right)
                    color: "social", // set the color of buttons (social, white)
                    enabled: true, // show/hide buttons (true, false)
                    font_size: 16, // font size for the buttons
                    labels: "cta", // button labels (cta, counts, null)
                    language: "en", // which language to use (see LANGUAGES)
                    networks: [
                      // which networks to include (see SHARING NETWORKS)
                      "facebook",
                      "twitter",
                      "email",
                      "linkedin",
                      "whatsapp",
                      "messenger",
                    ],
                    padding: 12, // padding within buttons (INTEGER)
                    radius: 10, // the corner radius on each button (INTEGER)
                    show_total: false,
                    size: 40, // the size of each button (INTEGER)

                    // OPTIONAL PARAMETERS

                    url: `${apiUrl}/api/job_detail.php?id=${story.jobid}&title=${story.job_titleslug}`,
                    image: story.job_photo.includes("images/jobs")
                      ? `${apiUrl}/${story.job_photo}`
                      : story.job_photo, // (defaults to og:image or twitter:image)
                    description: doSanitize(story.job_description), // (defaults to og:description or twitter:description)
                    title: story.job_title, // (defaults to og:title or twitter:title)
                    message: doSanitize(story.job_description), // (only for email sharing)
                    subject: story.job_title, // (only for email sharing)
                    username: "LuchukNg", // (only for twitter sharing)
                  }}
                />
              )}
              <div className="small text-left py-3">
                <i className="bi bi-person-circle"></i> Admin |{" "}
                <i className="bi bi-clock"></i>:{" "}
                {moment(job.createdAt).format("Do MMM YYYY h:mma")}
              </div>
              {job.job_photo !== undefined &&
                job.job_photo !== "" &&
                job.job_photo !== null && (
                  <ImageDisplay
                    url={`${job.job_photo}`}
                    title={job.job_title}
                    height={isMobile ? 300 : 400}
                  />
                )}
              <div
                style={{ overflowX: "auto" }}
                className="py-2 container"
                dangerouslySetInnerHTML={{
                  __html: story.job_description,
                }}
              />
              <div className="py-2 my-2">
                <h5>Responsibility</h5>
                {story.job_responsibility}
              </div>
              <div className="py-2 my-2">
                <h5>Minmum qualification</h5>
                {story.job_minimum_qual}
              </div>{" "}
              <div className="py-2 my-2">
                <h5>Years of experience</h5>
                {story.job_years_experience}
              </div>{" "}
              <div className="py-2 my-2">
                <h5>Location</h5>
                {story.job_location}
              </div>{" "}
              <div className="py-2 my-2">
                <h5>Salary</h5>
                {story.job_salary}
              </div>{" "}
              <div className="py-2 my-2">
                <h5>Application open</h5>
                from {moment(story.job_from).format("Do MMM YYYY")} to{" "}
                {moment(story.job_to).format("Do MMM YYYY")}
              </div>{" "}
              <div className="py-2 my-2">
                <h5>Status</h5>
                {story.job_status}
              </div>
            </div>
            <div className="col-md-4">
              <div className="h2 text-dark">Jobs</div>
              {jobs
                .filter((item) => item.job_titleslug !== title)
                .slice(0, 15)
                .map((item, i) => {
                  return (
                    <div
                      style={{ backgroundColor: "#ffffff" }}
                      key={i}
                      className="my-3 py-2 border-bottom"
                    >
                      <Link
                        className="text-decoration-none text-dark"
                        to={`/job/${item.job_titleslug}`}
                      >
                        <div className="row">
                          {item.job_photo !== undefined &&
                            item.job_photo !== "" &&
                            item.job_photo !== null && (
                              <div className="col-3">
                                <ImageDisplay
                                  url={`${item.job_photo}`}
                                  title={item.job_title}
                                  height={isMobile ? 60 : 70}
                                />{" "}
                              </div>
                            )}
                          <div className="col-9 mx-0 px-0">
                            <p className="h6">
                              {item.job_title} ({item.job_status})
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default JobDetail;
