import React, { useState, useContext, useEffect } from "react";
import { Header, Footer } from ".";
import { useNavigate } from "react-router-dom";
import { Formik, Field, setNestedObjectValues } from "formik";
import { DashboardMenu, NavigationBar } from "../components";
import { SiteContext } from "../context";
import moment from "moment";
import * as Yup from "yup";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageResize from "quill-image-resize-module-react";

const validationSchema = Yup.object().shape({
  category: Yup.string().required(),
  title: Yup.string().label("Title").required(),
  author: Yup.string().label("Author").required(),
  status: Yup.string().label("Status").required(),
});

const AdminNewsAdd = () => {
  let navigate = useNavigate();

  const { apiUrl, allCats, allSubCats, fbAccessToken } =
    useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [uploadedPic, setUploadedPic] = useState(null);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [story, setStory] = useState("");
  const [seos, setSeos] = useState(0);
  const [pubDate, setPubDate] = useState(new Date());
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (story === "" || story === undefined) {
      alert("Please provide the content for the news item.");
      setSubmitting(false);
      return;
    }

    let formData = new FormData();

    formData.append("category", values.category);
    formData.append("title", values.title);
    formData.append("title_slug", slugify(values.title));
    formData.append("source", values.source);
    formData.append("source_url", values.source_url);
    formData.append("imglink", values.imglink);
    formData.append("desc", story);
    formData.append("image", values.photo);
    formData.append("caption", values.caption);
    formData.append("tags", values.tags);
    formData.append("mdesc", values.mdesc);
    formData.append("seos", seos);
    formData.append("author", values.author);
    formData.append("publishdate", moment(pubDate).format("YYYY-MM-DD"));
    formData.append("status", values.status ? "Publish" : "Draft");
    formData.append("username", userData.username);
    formData.append("role", userData.role);
    formData.append("action", "create");

    return fetch(`${apiUrl}/api/news_process.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        alert(JSON.stringify(res));

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
          setTimeout(() => {
            navigate("/adminnews");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "News submission failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const filterSubCat = (cat) => {
    let filtered = allSubCats.filter(
      (item) => parseInt(item.ncatid) === parseInt(cat)
    );
    setFilteredSubCategories(filtered);
  };

  const doSeoScore = (text) => {
    let seoLength = text.length;

    if (seoLength < 5) {
      setSeos(0);
    } else if (seoLength < 15) {
      setSeos(1);
    } else if (seoLength < 30) {
      setSeos(2);
    } else {
      setSeos(3);
    }
  };

  const slugify = (string) => {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  };

  return (
    <div>
      <Header />
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar section={"News"} goback={true} />

              <h3>News Manage</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  category: "",
                  title: "",
                  source: "",
                  source_url: "",
                  imglink: "",
                  photo: "",
                  caption: "",
                  tags: "",
                  mdesc: "",
                  author: "",
                  status: "",
                  username: userData.username,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <div>
                    <form onSubmit={handleSubmit} encype="multipart/form-data">
                      <div className="row my-2">
                        <div className="col-md-8">
                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="category">Category</label>
                            </div>
                            <Field
                              as="select"
                              id="category"
                              name="category"
                              value={values.category}
                              onChangeText={handleChange("category")}
                              placeholder="Category"
                              placeholderTextColor="#999"
                              onBlur={() => {
                                handleBlur("category");
                              }}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            >
                              <option value="">Select...</option>
                              {allCats.length > 0 &&
                                allCats.map((item, i) => {
                                  return (
                                    <option key={i} value={item.ncatid}>
                                      {item.ncat}
                                    </option>
                                  );
                                })}
                            </Field>

                            {touched.category && errors.category && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.category && errors.category}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-2">
                            <div>
                              <label htmlFor="title">Title</label>
                            </div>
                            <Field
                              id="title"
                              name="title"
                              value={values.title}
                              onChangeText={handleChange("title")}
                              placeholder="Title"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("title")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.title && errors.title && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.title && errors.title}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-2 p-2">
                            <div>
                              <label htmlFor="desc">Story</label>
                            </div>
                            <ReactQuill
                              theme="snow"
                              value={story}
                              onChange={setStory}
                              style={{ height: 350, marginBottom: 20 }}
                            />
                          </div>

                          <div className="form-group my-2 py-2">
                            <div>
                              <label htmlFor="source">Source</label>
                            </div>
                            <Field
                              id="source"
                              name="source"
                              value={values.source}
                              onChangeText={handleChange("source")}
                              placeholder="Source"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("source")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.source && errors.source && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.source && errors.source}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-2">
                            <div>
                              <label htmlFor="source_url">
                                Source url (https://)
                              </label>
                            </div>
                            <Field
                              id="source_url"
                              name="source_url"
                              value={values.source_url}
                              onChangeText={handleChange("source_url")}
                              placeholder="Source url"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("source_url")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.source_url && errors.source_url && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.source_url && errors.source_url}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group my-3">
                            <div>
                              <label htmlFor="photo"> Upload picture</label>
                            </div>
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "photo",
                                  event.currentTarget.files[0]
                                );
                                setUploadedPic(event.currentTarget.files[0]);
                              }}
                            />
                            {uploadedPic && (
                              <img
                                className="my-2"
                                src={URL.createObjectURL(uploadedPic)}
                                class="img-fluid mt-2"
                                width={200}
                                height={100}
                                id="output_image"
                              />
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="caption">Picture caption</label>
                            </div>
                            <Field
                              id="caption"
                              name="caption"
                              value={values.caption}
                              onChangeText={handleChange("caption")}
                              placeholder="Picture caption"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("caption")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.caption && errors.caption && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.caption && errors.caption}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="imglink">Image url</label>
                            </div>
                            <Field
                              id="imglink"
                              name="imglink"
                              value={values.imglink}
                              onChangeText={handleChange("imglink")}
                              placeholder="Image url"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("imglink")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.imglink && errors.imglink && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.imglink && errors.imglink}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="author">Author</label>
                            </div>
                            <Field
                              id="author"
                              name="author"
                              value={values.author}
                              onChangeText={handleChange("author")}
                              placeholder="Author"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("Author")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.author && errors.author && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.author && errors.author}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="tags">
                                Tags (separate with commas)
                              </label>
                            </div>
                            <Field
                              id="tags"
                              name="tags"
                              value={values.tags}
                              onChangeText={handleChange("tags")}
                              placeholder="Tags"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("tags")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.tags && errors.tags && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.tags && errors.tags}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="mdesc">Meta description</label>
                            </div>
                            <Field
                              id="mdesc"
                              name="mdesc"
                              value={values.mdesc}
                              onChangeText={handleChange("mdesc")}
                              placeholder="Meta description"
                              placeholderTextColor="#999"
                              onBlur={() => {
                                handleBlur("mdesc");
                                doSeoScore(values.mdesc);
                              }}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.mdesc && errors.mdesc && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.mdesc && errors.mdesc}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="seos">SEO Score</label>
                            </div>
                            <Field
                              id="seos"
                              disabled
                              name="seos"
                              value={seos}
                              onChangeText={handleChange("seos")}
                              placeholder="SEOs"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("seos")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.seos && errors.seos && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.seos && errors.seos}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="pubdate">Publication date</label>
                            </div>
                            <DatePicker
                              dateFormat={"yyyy-MM-dd"}
                              selected={pubDate}
                              onChange={(date) => {
                                setPubDate(date);
                              }}
                            />
                          </div>

                          <div className="form-group my-1 py-2">
                            <div>
                              <label className="px-2" htmlFor="status">
                                Status
                              </label>
                              <Field type="checkbox" name="status" />{" "}
                              {values.status ? "Published" : "Draft"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        buttonType="outline"
                        onClick={handleSubmit}
                        title="Submit"
                        disabled={!isValid || isSubmitting}
                        loading={isSubmitting}
                        className="btn btn-primary"
                      >
                        Submit
                      </button>

                      <button
                        onClick={() => navigate(-1)}
                        title="Cancel"
                        className="btn btn-secondary mx-2"
                      >
                        Cancel
                      </button>
                    </form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminNewsAdd;
