import React from 'react';
import { DiscountCodeBox } from './';

const PopUpAlert = ({ isMobile, icon, title, message, type, showAlert, setShowAlert }) => {

    return (
        <div>
            {showAlert && 
                <div style={{ 'position': 'fixed', 'top': 0, 'left': 0, 'zIndex': 400 }} className='w-100 h-100 bg-dark bg-opacity-75' />
            }

        {showAlert ? 
                <div style={isMobile ? { 'backgroundColor': '#ffffff', 'overflow':'hidden', 'position': 'fixed', 'top': '10%', 'bottom': '10%', 'left': '5%', 'right': '5%', 'width': '90%', 'height': '80%', 'zIndex': 500 } : { 'backgroundColor': '#ffffff', 'overflow':'hidden', 'position': 'fixed', 'top': '15%', 'bottom':'15%', 'width': '60%', 'height': '70%', 'left': '20%', 'zIndex': 500 }} className={'row'} >
                        <div className='col-md-6 p-5 text-center'>

                            <h2 className='my-3 py-2'>{'JOIN OUR FAMILY AND GET 20% OFF'}</h2>
                            
                        <div className='h5'>{'By signing up, you will also receive amazing discounts, info on deals & much more!'}</div>
                        <DiscountCodeBox />
                        
                        </div>

                        <div className='col-md-6 mx-0 px-0'>
                            <img style={{ 'position': 'relative', 'zIndex': 90, 'objectFit':'cover'}} src={icon} width={'100%'} alt='stone' />
                            <div style={{'position':'absolute', 'zIndex': 100, 'right':10, 'top': 10}} className='m-1 p-1'>
                                <button className={isMobile ? 'bg-dark text-white rounded-circle p-1 border-0' : 'bg-dark text-white rounded-circle px-2 py-1'} onClick={() => setShowAlert(false)}>X</button>
                            </div>
                        </div>
                    </div>
                :
        <div style={{'position':'fixed', 'top':0, 'left': '-100%', 'zIndex':400}} className='w-100 h-100 bg-dark bg-opacity-75'>
        </div>
        }
         
      </div>
  )
}

export default PopUpAlert;