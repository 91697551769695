import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import { TableJobCat, NavigationBar } from "../components";
import { Formik, Field } from "formik";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import ReactModal from "react-modal";
import { DashboardMenu } from "../components";
import { SiteContext } from "../context";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const validationSchema = Yup.object().shape({
  catname: Yup.string()
    .label("Category")
    .required()
    .min(3, "Must have at least 3 characters"),
});

const AdminJobCat = () => {
  const { apiUrl, jobcategories, isMobile, slugify } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [story, setStory] = useState("");
  const [items, setItems] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [uploadedPic, setUploadedPic] = useState(null);
  const [category, setCategory] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    getJobsCat();
  }, []);

  const getJobsCat = () => {
    fetch(`${apiUrl}/api/jobcategory.php?action=fetch`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setItems(res.rows);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (story === "" || story === undefined) {
      alert("Please provide the content for the category.");
      setSubmitting(false);
      return;
    }

    let action = values.id == "" ? "create" : "update";
    let formData = new FormData();

    formData.append("jcat", values.catname);
    formData.append("jcatslug", slugify(values.catname));
    formData.append("jcatdesc", story);
    values.id !== "" && formData.append("cat", values.id);
    formData.append("action", action);

    return fetch(`${apiUrl}/api/jobcategory.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === 200) {
          resetForm({});
          setShowModal(false);
          setSubmitting(false);
          setMsg(res.msg);
          setStory("");
          setTimeout(() => {
            setMsg("");
            getJobsCat();
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
          setSubmitting(false);
        } else {
          alert(
            "Failure",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const isEmpty = (object) => {
    return Object.keys(object).length === 0;
  };
  const handleAdd = () => {
    setUpdateItem({});
    setStory("");
  };

  const handleUpdate = (item) => {
    setUpdateItem(item);
    setStory(item.jcatdesc);
    setShowUpdateModal(true);
  };

  const doDelete = (id) => {
    let formData = new FormData();

    formData.append("cat", id);
    formData.append("role", userData.role);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/jobcategory.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          getJobsCat();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <ReactModal
        isOpen={showUpdateModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? "15%" : "10%",
            bottom: isMobile ? "15%" : "20%",
            left: isMobile ? "2%" : "25%",
            right: isMobile ? "2%" : "25%",
            width: isMobile ? "96%" : "50%",
            height: isMobile ? "70%" : "70%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <div className="container">
            {errorMsg !== "" ? (
              <span className="p-2 my-2 border border-danger text-danger">
                {errorMsg}
              </span>
            ) : null}

            <div className="d-flex justify-content-end">
              <span
                className="btn btn-secondary my-2"
                onClick={() => setShowUpdateModal(false)}
              >
                <i className="bi bi-x-square h4 text-white"></i>
              </span>
            </div>

            <h3>
              Categories | <Link to="/adminjobs">Jobs</Link>
            </h3>

            <Formik
              initialValues={{
                id: updateItem.jcatid ? updateItem.jcatid : "",
                catname: updateItem.jcat ? updateItem.jcat : "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, {
                  setSubmitting,
                  resetForm,
                  setShowUpdateModal,
                });
                setTimeout(() => {
                  setShowUpdateModal(false);
                }, 2000);
              }}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                isValid,
                isSubmitting,
                handleBlur,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} encype="multipart/form-data">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group row my-2">
                        <div className="col-md-3 p-2 h6">
                          <label htmlFor="catname">Category</label>
                        </div>
                        <div className="col-md-9">
                          <Field
                            id="catname"
                            name="catname"
                            value={values.catname}
                            onBlur={handleBlur("catname")}
                            onChange={handleChange("catname")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.catname && errors.catname && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.catname && errors.catname}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group row my-2">
                        <div className="col-md-3 p-2 h6">
                          <label htmlFor="catdesc">Description</label>
                        </div>
                        <div className="col-md-9">
                          <ReactQuill
                            theme="snow"
                            value={story}
                            onChange={setStory}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    title="Update"
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}{" "}
                    Update
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
      <Header />
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar section={"Jobs"} goback={true} />

              <h3>
                Categories | <Link to="/adminjobs">Jobs</Link>
              </h3>

              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    New category
                  </button>
                </div>
              </div>

              <div className="container">
                {errorMsg !== "" ? (
                  <span className="p-2 my-2 border border-danger text-danger">
                    {errorMsg}
                  </span>
                ) : null}
                <Formik
                  initialValues={{
                    id: "",
                    catname: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    handleSubmit(values, { setSubmitting, resetForm });
                  }}
                >
                  {({
                    handleChange,
                    values,
                    handleSubmit,
                    errors,
                    isValid,
                    isSubmitting,
                    handleBlur,
                    touched,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} encype="multipart/form-data">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row my-2">
                            <div className="col-md-3 p-2 h6">
                              <label htmlFor="catname">Category</label>
                            </div>
                            <div className="col-md-9">
                              <Field
                                id="catname"
                                name="catname"
                                value={values.catname}
                                onBlur={handleBlur("catname")}
                                onChange={handleChange("catname")}
                                autoCapitalize="none"
                                style={{
                                  padding: 4,
                                }}
                                className="form-control"
                              />
                              {touched.catname && errors.catname && (
                                <div className="py-1">
                                  <span className="text-danger">
                                    {touched.catname && errors.catname}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="form-group row my-2">
                            <div className="col-md-3 p-2 h6">
                              <label htmlFor="catdesc">Description</label>
                            </div>
                            <div className="col-md-9">
                              <ReactQuill
                                theme="snow"
                                value={story}
                                onChange={setStory}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        onClick={handleSubmit}
                        title={isEmpty(updateItem) ? "Add" : "Update"}
                        disabled={!isValid || isSubmitting}
                        className="btn btn-primary"
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}{" "}
                        {isEmpty(updateItem) ? "Add" : "Update"}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>

              <TableJobCat
                items={items}
                onDelete={doDelete}
                handleUpdate={handleUpdate}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminJobCat;
