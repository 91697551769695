import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "../screens";
import { NavigationBar, PayButton } from ".";
import moment from "moment";
import { useParams, useLocation, Link, Navigate } from "react-router-dom";
import { SiteContext } from "../context";

const OrderComplete = () => {
  const { userData, apiUrl, formatAmount, singleProduct } =
    useContext(SiteContext);

  let { ref } = useParams();
  let location = useLocation();

  const [order, setOrder] = useState({});
  const [orderedItems, setOrderedItems] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getOrder();
  }, []);

  const getOrder = () => {
    fetch(`${apiUrl}/api/orders.php?action=fetchsingle&orderref=${ref}`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setOrder(res.order);
          setOrderedItems(JSON.parse(res.order.orderdetails));
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doCustomerUpdate = (orderref) => {
    fetch(
      `${apiUrl}/api/orders.php?action=customerupdate&user=${userData.id}&orderref=${orderref}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setOrder(res.orderdetail);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doCustomerPayment = (orderref) => {
    fetch(
      `${apiUrl}/api/orders.php?action=customerpayment&user=${userData.id}&orderref=${orderref}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setOrder(res.orderdetail);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Header />
      <div>
        <div className="row">
          <div className="col-12">
            <div className="container my-2">
              <NavigationBar section={"Orders"} goback={true} />

              <div className="my-2 py-1">
                <Link
                  className="my-3 py-2 text-decoration-none"
                  to="/mytransactions"
                >
                  My order history
                </Link>
              </div>

              <h3>Order confirmation!</h3>
              <p className="my-2">You have successfully placed your order</p>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  <div className="p-2">
                    {msg !== "" ? (
                      <span className="text-dark">{msg}</span>
                    ) : null}{" "}
                    {errorMsg !== "" ? (
                      <span className="text-danger">{errorMsg}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              {order.hasOwnProperty("orderref") ? (
                <div>
                  {order.paymentstatus === "Unpaid" &&
                    order.paymentmode === "Card" && (
                      <PayButton
                        order={order}
                        setOrder={setOrder}
                        setMsg={setMsg}
                        setErrorMsg={setErrorMsg}
                      />
                    )}

                  <div className="row">
                    <div className="col-md-6 my-3">
                      <span className="h6">Order Ref:</span> #{order.orderref}
                      <br />
                      <span className="h6">
                        Item{orderedItems.length > 1 && "s"}:
                      </span>{" "}
                      {singleProduct
                        ? order.productname
                        : orderedItems &&
                          orderedItems.map((item, i) => (
                            <div className="small" key={i}>
                              {item.item} x {item.qty}{" "}
                              {formatAmount(item.total)}
                            </div>
                          ))}
                      <br />
                      <span className="h6">Coupon:</span> {order.order_currency}
                      {order.coupon}
                      <br />
                      <span className="h6">Shipping:</span>{" "}
                      {order.order_currency}
                      {order.shipping}
                      <br />
                      <span className="h6">Total amount:</span>{" "}
                      {order.order_currency}
                      {order.amount}
                      <br />
                      <span className="h6">Order time:</span>{" "}
                      {moment(order.createdAt).format("Do MMM YYYY h:mma")}
                      <br />
                    </div>
                    <div className="col-md-6 my-3">
                      <span className="h6">Payment status:</span>{" "}
                      {order.paymentstatus} <br />
                      <span className="h6">Payment mode:</span>{" "}
                      {order.paymentmode}
                      <br />
                      {/* {order.paymentstatus === 'Unpaid' && order.paymentmode === 'Card' && order.customerid === userData.id && <PayButton order={order} setOrder={setOrder} setMsg={setMsg} setErrorMsg={setErrorMsg} /> }<br /> */}
                    </div>
                    <div className="col-md-6">
                      <h5>Customer</h5>
                      <span className="h6">Name:</span>{" "}
                      {order.customerfirstname} {order.customerlastname}
                      <br />
                      <span className="h6">Phone:</span> {order.customerphone}
                      <br />
                      <span className="h6">Address:</span>{" "}
                      {order.customeraddress}
                      <br />
                      <span className="h6">Delivery:</span>{" "}
                      {order.customerdeliverystatus}{" "}
                      {userData.id === order.customerid &&
                        order.customerdeliverystatus !== "Delivered" && (
                          <button
                            className="bg-success bg-opacity-50 text-white"
                            onClick={() => doCustomerUpdate(order.orderref)}
                          >
                            Confirm delivery
                          </button>
                        )}
                      <br />
                      {order.customerdeliverystatus === "Delivered" ? (
                        <div>
                          Delivery time:{" "}
                          {moment(order.customerdeliverystatustime).format(
                            "Do MMM YYYY h:mma"
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div></div>
                  </div>
                </div>
              ) : (
                <div>
                  <p>Order not found!</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderComplete;
