import React, { useState, useContext, useEffect } from "react";
import { Formik, Field } from "formik";
import { Header, Footer } from "./";
import { DashboardMenu, NavigationBar } from "../components";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  title: Yup.string().label("Title").required(),
  description: Yup.string()
    .label("Description")
    .required()
    .min(5, "Must have at least 5 characters"),
  status: Yup.string().label("Job status").required(),
});

const AdminJobAdd = () => {
  let navigate = useNavigate();

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [uploadedPic, setUploadedPic] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const { apiUrl, userData, slugify, jobcategories } = useContext(SiteContext);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let formData = new FormData();

    formData.append("category", values.category);
    formData.append("title", values.title);
    formData.append("titleslug", slugify(values.title));
    formData.append("description", values.description);
    formData.append("responsibility", values.job_responsibility);
    formData.append("years_exp", values.years_exp);
    formData.append("min_qual", values.min_qual);
    formData.append("fromdate", moment(startDate).format("YYYY-MM-DD"));
    formData.append("todate", moment(endDate).format("YYYY-MM-DD"));
    formData.append("status", values.status ? "Open" : "Closed");
    formData.append("location", values.job_location);
    formData.append("image", values.photo);
    formData.append("salary", values.job_salary);
    formData.append("role", userData && userData.role);
    formData.append("action", "create");

    return fetch(`${apiUrl}/api/jobs.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
          setTimeout(() => {
            navigate("/adminjobs");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Job submission failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div>
      <Header />

      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar section={"Jobs"} goback={true} />

              <h3 className="my-3">Add job</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  category: "",
                  title: "",
                  description: "",
                  job_responsibility: "",
                  min_qual: "",
                  years_exp: "",
                  job_location: "",
                  photo: "",
                  job_salary: "",
                  status: true,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} encype="multipart/form-data">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group my-2">
                          <div>
                            <label htmlFor="category">Category</label>
                          </div>
                          <Field
                            as="select"
                            id="category"
                            name="category"
                            value={values.category}
                            onBlur={handleBlur("category")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          >
                            <option value="">Select category...</option>
                            {jobcategories.length > 0 &&
                              jobcategories.map((item, i) => {
                                return (
                                  <option key={i} value={item.jcat}>
                                    {item.jcat}
                                  </option>
                                );
                              })}
                          </Field>

                          {touched.category && errors.category && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.category && errors.category}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="title">Title</label>
                          </div>
                          <Field
                            id="title"
                            name="title"
                            value={values.title}
                            onBlur={handleBlur("title")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />

                          {touched.title && errors.title && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.title && errors.title}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="description">Description</label>
                          </div>
                          <Field
                            as="textarea"
                            id="description"
                            name="description"
                            value={values.description}
                            onBlur={handleBlur("description")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                            row="5"
                          />
                          {touched.description && errors.description && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.description && errors.description}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="job_responsibility">
                              Responsibilities
                            </label>
                          </div>
                          <Field
                            as="textarea"
                            id="job_responsibility"
                            name="job_responsibility"
                            value={values.job_responsibility}
                            onBlur={handleBlur("job_responsibility")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                            row="5"
                          />
                          {touched.job_responsibility &&
                            errors.job_responsibility && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.job_responsibility &&
                                    errors.job_responsibility}
                                </span>
                              </div>
                            )}
                        </div>

                        <div className="form-group my-3">
                          <div>
                            <label htmlFor="photo"> Upload picture</label>
                          </div>
                          <input
                            id="photo"
                            name="photo"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "photo",
                                event.currentTarget.files[0]
                              );
                              setUploadedPic(event.currentTarget.files[0]);
                            }}
                          />
                          {uploadedPic && (
                            <img
                              className="my-2"
                              src={URL.createObjectURL(uploadedPic)}
                              class="img-fluid mt-2"
                              width={200}
                              height={100}
                              id="output_image"
                            />
                          )}
                        </div>

                        <div className="form-group my-2">
                          <div>
                            <label htmlFor="years_exp">
                              Years of experience
                            </label>
                          </div>
                          <Field
                            as="select"
                            id="years_exp"
                            name="years_exp"
                            value={values.years_exp}
                            onBlur={handleBlur("years_exp")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          >
                            <option value="">Select...</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </Field>

                          {touched.years_exp && errors.years_exp && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.years_exp && errors.years_exp}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="min_qual">
                              Minimum qualification
                            </label>
                          </div>
                          <Field
                            id="min_qual"
                            name="min_qual"
                            value={values.min_qual}
                            onBlur={handleBlur("min_qual")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.min_qual && errors.min_qual && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.min_qual && errors.min_qual}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-2">
                          <div>
                            <label htmlFor="job_location">Location</label>
                          </div>
                          <Field
                            id="job_location"
                            name="job_location"
                            value={values.job_location}
                            onBlur={handleBlur("job_location")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />

                          {touched.job_location && errors.job_location && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.job_location && errors.job_location}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-2">
                          <div>
                            <label htmlFor="job_salary">Salary</label>
                          </div>
                          <Field
                            id="job_salary"
                            name="job_salary"
                            value={values.job_salary}
                            onBlur={handleBlur("job_salary")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />

                          {touched.job_salary && errors.job_salary && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.job_salary && errors.job_salary}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="start_date">From</label>
                          </div>
                          <DatePicker
                            dateFormat={"yyyy-MM-dd"}
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                            }}
                          />
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="end_date">To</label>
                          </div>
                          <DatePicker
                            dateFormat={"yyyy-MM-dd"}
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            minDate={startDate}
                          />
                        </div>

                        <div className="form-group my-1 py-2">
                          <div>
                            <label htmlFor="status">Status</label>{" "}
                            <Field type="checkbox" name="status" />{" "}
                            {values.status ? "Open" : "Closed"}
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      onClick={handleSubmit}
                      title="Submit"
                      disabled={!isValid || isSubmitting}
                      className="btn btn-primary my-2"
                    >
                      Submit
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminJobAdd;
