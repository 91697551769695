import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import { TableProduct, DashboardMenu, ProductForm } from "../components";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import { AddProductModal, UpdateProductModal } from "../modals";
import { SiteContext } from "../context";

const AdminProducts = () => {
  const { apiUrl, slugify } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [items, setItems] = useState([]);
  const [itemsCat, setItemsCat] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    getProducts();
    getProductsCat();
  }, []);

  useEffect(() => {
    getProducts();
  }, [items]);

  const getProducts = () => {
    fetch(`${apiUrl}/api/products.php?action=fetch`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItems(res.products);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProductsCat = () => {
    fetch(`${apiUrl}/api/productcategory.php?action=fetch`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItemsCat(res.categories);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal }) => {
    let action = values.id === "" ? "create" : "update";
    let formData = new FormData();

    formData.append("category", values.category);
    formData.append("catslug", slugify(values.category));
    formData.append("name", values.name);
    formData.append("nameslug", slugify(values.name));
    formData.append("sku", values.sku);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("promo", values.promo !== "" ? values.promo : 0);
    formData.append("size", values.size);
    formData.append("qty", values.qty);
    formData.append("qty_notify", values.qty_notify);
    formData.append("status", values.status ? 1 : 0);
    formData.append("picture", values.picture);
    formData.append("picture2", values.picture2);
    formData.append("picture3", values.picture3);
    formData.append("product", values.id);
    formData.append("action", action);

    const url =
      values.id === ""
        ? `${apiUrl}/api/products.php`
        : `${apiUrl}/api/products.php?id=${values.id}`;
    const sendType = "post";

    return fetch(url, {
      method: sendType,
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === 200) {
          setMsg(res.msg);
          resetForm({});
          setShowModal(false);
          setSubmitting(false);
          getProducts();
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 4000);
          setSubmitting(false);
        } else {
          alert(
            "Failure",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleAdd = () => {
    setShowModal(true);
  };

  const handleUpdate = (item) => {
    setUpdateItem(item);
    setShowUpdateModal(true);
  };

  const doDelete = (id) => {
    let formData = new FormData();

    formData.append("product", id);
    formData.append("role", userData.role);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/products.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          getProducts();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <AddProductModal
        categories={itemsCat}
        handleSubmit={handleSubmit}
        showModal={showModal}
        setShowModal={setShowModal}
        errorMsg={errorMsg}
      />
      <UpdateProductModal
        categories={itemsCat}
        updateItem={updateItem}
        handleSubmit={handleSubmit}
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        errorMsg={errorMsg}
      />
      <Header />
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>
                Products | <Link to="/adminproductscat">Categories</Link> |{" "}
                <Link to="/adminshipping">Shipping</Link> |{" "}
                <Link to="/admindiscounts">Discounts</Link>
              </h3>

              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    New product
                  </button>
                </div>
              </div>

              <TableProduct
                items={items}
                onDelete={doDelete}
                handleUpdate={handleUpdate}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminProducts;
