import React, { lazy, Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Checkout } from "../components";
import { Home } from "../screens";
import ErrorBoundary from "../ErrorBoundary";

import { SiteContext } from "../context";

const ProductDetail = lazy(() => import("../components/ProductDetail"));
const OrderComplete = lazy(() => import("../components/OrderComplete"));
const OrderForm = lazy(() => import("../components/OrderForm"));
const Cart = lazy(() => import("../components/Cart"));

const NothingMatch = lazy(() => import("../screens/NothingMatch"));

const Shipping = lazy(() => import("../screens/Shipping"));
const Discounts = lazy(() => import("../screens/Discounts"));
const ForgotPassword = lazy(() => import("../screens/ForgotPassword"));
const NewsCategory = lazy(() => import("../screens/NewsCategory"));
const NewsSubCategory = lazy(() => import("../screens/NewsSubCategory"));
const NewsDetail = lazy(() => import("../screens/NewsDetail"));
const AdminNews = lazy(() => import("../screens/AdminNews"));
const AdminNewsAdd = lazy(() => import("../screens/AdminNewsAdd"));
const AdminNewsUpdate = lazy(() => import("../screens/AdminNewsUpdate"));
const AdminNewsCategories = lazy(() =>
  import("../screens/AdminNewsCategories")
);
const AdminNewsSubCategories = lazy(() =>
  import("../screens/AdminNewsSubCategories")
);
const Login = lazy(() => import("../screens/Login"));
const Signup = lazy(() => import("../screens/Signup"));
const Profile = lazy(() => import("../screens/Profile"));

const Register = lazy(() => import("../screens/Register"));
const Account = lazy(() => import("../screens/Account"));
const Users = lazy(() => import("../screens/Users"));
const UserDashboard = lazy(() => import("../screens/UserDashboard"));
const UserProfileDisplay = lazy(() => import("../screens/UserProfileDisplay"));
const UpdatePassword = lazy(() => import("../screens/UpdatePassword"));
const Contact = lazy(() => import("../screens/Contact"));
const SitePages = lazy(() => import("../screens/SitePages"));
const SitePageAdd = lazy(() => import("../screens/SitePageAdd"));
const SitePageUpdate = lazy(() => import("../screens/SitePageUpdate"));
const PhotoGallery = lazy(() => import("../screens/PhotoGallery"));
const FaqsCategory = lazy(() => import("../screens/FaqsCategory"));
const Faqs = lazy(() => import("../screens/Faqs"));
const FaqsList = lazy(() => import("../screens/FaqsList"));
const QuoteRequestUpdate = lazy(() => import("../screens/QuoteRequestUpdate"));
const AdminQuoteRequests = lazy(() => import("../screens/AdminQuoteRequests"));
const AdminProducts = lazy(() => import("../screens/AdminProducts"));
const AdminProductsCat = lazy(() => import("../screens/AdminProductsCat"));
const AdminJobCat = lazy(() => import("../screens/AdminJobCat"));
const AdminFaqs = lazy(() => import("../screens/AdminFaqs"));
const PhotoAnimationAdd = lazy(() => import("../screens/PhotoAnimationAdd"));
const PhotoAnimationUpdate = lazy(() =>
  import("../screens/PhotoAnimationUpdate")
);
const AdminPhotoAnimation = lazy(() =>
  import("../screens/AdminPhotoAnimation")
);
const TestimonialUpdate = lazy(() => import("../screens/TestimonialUpdate"));
const AdminTestimonials = lazy(() => import("../screens/AdminTestimonials"));
const FaqAdd = lazy(() => import("../screens/FaqAdd"));
const FaqUpdate = lazy(() => import("../screens/FaqUpdate"));
const CommentUpdate = lazy(() => import("../screens/CommentUpdate"));
const ProductsCategory = lazy(() => import("../screens/ProductsCategory"));
const Testimonial = lazy(() => import("../screens/Testimonial"));
const Activation = lazy(() => import("../screens/Activation"));
const JobCategoryList = lazy(() => import("../screens/JobCategoryList"));
const JobCategory = lazy(() => import("../screens/JobCategory"));
const JobCategoryDetail = lazy(() => import("../screens/JobCategoryDetail"));
const AdminJobs = lazy(() => import("../screens/AdminJobs"));
const AdminJobAdd = lazy(() => import("../screens/AdminJobAdd"));
const AdminJobUpdate = lazy(() => import("../screens/AdminJobUpdate"));
const Jobs = lazy(() => import("../screens/Jobs"));
const JobDetail = lazy(() => import("../screens/JobDetail"));

const SiteConfigUpdate = lazy(() => import("../screens/SiteConfigUpdate"));
const SiteConfig = lazy(() => import("../screens/SiteConfig"));
const PageDetail = lazy(() => import("../screens/PageDetail"));
const AdminNewsletters = lazy(() => import("../screens/AdminNewsletters"));
const NewsletterAdd = lazy(() => import("../screens/NewsletterAdd"));
const NewsletterUpdate = lazy(() => import("../screens/NewsletterUpdate"));
const AdminPhotoGallery = lazy(() => import("../screens/AdminPhotoGallery"));
const PhotoGalleryAdd = lazy(() => import("../screens/PhotoGalleryAdd"));
const PhotoGalleryAddMultiple = lazy(() =>
  import("../screens/PhotoGalleryAddMultiple")
);
const PhotoGalleryUpdate = lazy(() => import("../screens/PhotoGalleryUpdate"));
const AdminPhotoGalleryCat = lazy(() =>
  import("../screens/AdminPhotoGalleryCat")
);
const PhotoGalleryCatAdd = lazy(() => import("../screens/PhotoGalleryCatAdd"));
const PhotoGalleryCatUpdate = lazy(() =>
  import("../screens/PhotoGalleryCatUpdate")
);
const AdminBannerAds = lazy(() => import("../screens/AdminBannerAds"));
const AdminBannerAdsUpdate = lazy(() =>
  import("../screens/AdminBannerAdsUpdate")
);
const AdminBannerAdsAdd = lazy(() => import("../screens/AdminBannerAdsAdd"));
const AdminComments = lazy(() => import("../screens/AdminComments"));
const Products = lazy(() => import("../screens/Products"));
const Transactions = lazy(() => import("../screens/Transactions"));
const TransactionsUser = lazy(() => import("../screens/TransactionsUser"));
const TransactionDetail = lazy(() => import("../screens/TransactionDetail"));
const AboutUs = lazy(() => import("../screens/About"));

const SiteRoutes = () => {
  const { isLoggedOn } = useContext(SiteContext);

  const loggedin = localStorage.getItem("loggedin");

  const userloggedOn = loggedin || isLoggedOn;

  const RequireAuth = ({ children }) => {
    if (!userloggedOn) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  const AuthNav = () => {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/activation/:rkey/:email" element={<Activation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/post-testimonial" element={<Testimonial />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/category/:slug" element={<ProductsCategory />} />
        <Route path="/products/:slug" element={<ProductDetail />} />
        <Route path="/categories" element={<JobCategoryList />}>
          <Route path="" element={<JobCategory />} />
          <Route path="category/:slug" element={<JobCategoryDetail />} />
        </Route>
        <Route path="/category/:id/:title" element={<NewsCategory />} />
        <Route
          path="/sub-category/:id/:section/:sid/:title"
          element={<NewsSubCategory />}
        />
        <Route path="/:id/:title" element={<NewsDetail />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/job/:title" element={<JobDetail />} />
        <Route
          path="/adminnews"
          element={
            <RequireAuth>
              <AdminNews />
            </RequireAuth>
          }
        />
        <Route
          path="/addnews"
          element={
            <RequireAuth>
              <AdminNewsAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatenews"
          element={
            <RequireAuth>
              <AdminNewsUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnewscategories"
          element={
            <RequireAuth>
              <AdminNewsCategories />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnewssubcategories"
          element={
            <RequireAuth>
              <AdminNewsSubCategories />
            </RequireAuth>
          }
        />
        <Route
          path="/addorder"
          element={
            <RequireAuth>
              <OrderForm />
            </RequireAuth>
          }
        />
        <Route
          path="/transactions"
          element={
            <RequireAuth>
              <Transactions />
            </RequireAuth>
          }
        />
        <Route
          path="/mytransactions"
          element={
            <RequireAuth>
              <TransactionsUser />
            </RequireAuth>
          }
        />
        <Route
          path="/order/:orderref"
          element={
            <RequireAuth>
              <TransactionDetail />
            </RequireAuth>
          }
        />
        <Route
          path="/ordercomplete/:ref"
          element={
            <RequireAuth>
              <OrderComplete />
            </RequireAuth>
          }
        />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/cart" element={<Cart />} />
        <Route
          path="/users"
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path="/bannerads"
          element={
            <RequireAuth>
              <AdminBannerAds />
            </RequireAuth>
          }
        />
        <Route
          path="/banneradadd"
          element={
            <RequireAuth>
              <AdminBannerAdsAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/banneradupdate"
          element={
            <RequireAuth>
              <AdminBannerAdsUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/addpage"
          element={
            <RequireAuth>
              <SitePageAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatepage"
          element={
            <RequireAuth>
              <SitePageUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminpages"
          element={
            <RequireAuth>
              <SitePages />
            </RequireAuth>
          }
        />
        <Route
          path="/addfaq"
          element={
            <RequireAuth>
              <FaqAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatefaq"
          element={
            <RequireAuth>
              <FaqUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminfaqs"
          element={
            <RequireAuth>
              <AdminFaqs />
            </RequireAuth>
          }
        />
        <Route
          path="/addphotoanimation"
          element={
            <RequireAuth>
              <PhotoAnimationAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatephotoanimation"
          element={
            <RequireAuth>
              <PhotoAnimationUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminphotoanimation"
          element={
            <RequireAuth>
              <AdminPhotoAnimation />
            </RequireAuth>
          }
        />
        <Route
          path="/updatetestimonial"
          element={
            <RequireAuth>
              <TestimonialUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/admintestimonials"
          element={
            <RequireAuth>
              <AdminTestimonials />
            </RequireAuth>
          }
        />
        <Route
          path="/updatecomment"
          element={
            <RequireAuth>
              <CommentUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/admincomments"
          element={
            <RequireAuth>
              <AdminComments />
            </RequireAuth>
          }
        />
        <Route
          path="/updatequoterequest"
          element={
            <RequireAuth>
              <QuoteRequestUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminquoterequests"
          element={
            <RequireAuth>
              <AdminQuoteRequests />
            </RequireAuth>
          }
        />
        <Route
          path="/addphotogallery"
          element={
            <RequireAuth>
              <PhotoGalleryAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/addphotogallerymultiple"
          element={
            <RequireAuth>
              <PhotoGalleryAddMultiple />
            </RequireAuth>
          }
        />
        <Route
          path="/updatephotogallery"
          element={
            <RequireAuth>
              <PhotoGalleryUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminphotogallery"
          element={
            <RequireAuth>
              <AdminPhotoGallery />
            </RequireAuth>
          }
        />
        <Route
          path="/addphotogallerycat"
          element={
            <RequireAuth>
              <PhotoGalleryCatAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatephotogallerycat"
          element={
            <RequireAuth>
              <PhotoGalleryCatUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminphotogallerycat"
          element={
            <RequireAuth>
              <AdminPhotoGalleryCat />
            </RequireAuth>
          }
        />
        <Route
          path="/addnewsletter"
          element={
            <RequireAuth>
              <NewsletterAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatenewsletter"
          element={
            <RequireAuth>
              <NewsletterUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnewsletters"
          element={
            <RequireAuth>
              <AdminNewsletters />
            </RequireAuth>
          }
        />
        <Route
          path="/adminsiteupdate"
          element={
            <RequireAuth>
              <SiteConfigUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminsiteconfig"
          element={
            <RequireAuth>
              <SiteConfig />
            </RequireAuth>
          }
        />
        <Route
          path="/adminproducts"
          element={
            <RequireAuth>
              <AdminProducts />
            </RequireAuth>
          }
        />
        <Route
          path="/adminproductscat"
          element={
            <RequireAuth>
              <AdminProductsCat />
            </RequireAuth>
          }
        />
        <Route
          path="/adminshipping"
          element={
            <RequireAuth>
              <Shipping />
            </RequireAuth>
          }
        />
        <Route
          path="/admindiscounts"
          element={
            <RequireAuth>
              <Discounts />
            </RequireAuth>
          }
        />
        <Route
          path="/adminjobcat"
          element={
            <RequireAuth>
              <AdminJobCat />
            </RequireAuth>
          }
        />
        <Route
          path="/adminjobs"
          element={
            <RequireAuth>
              <AdminJobs />
            </RequireAuth>
          }
        />
        <Route
          path="/adminjobadd"
          element={
            <RequireAuth>
              <AdminJobAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/adminjobupdate"
          element={
            <RequireAuth>
              <AdminJobUpdate />
            </RequireAuth>
          }
        />
        <Route path="/photo-gallery" element={<PhotoGallery />} />
        <Route path="/page/:id" element={<PageDetail />} />
        <Route path="/faqs" element={<FaqsList />}>
          <Route path="" element={<Faqs />} />
          <Route path="category/:slug" element={<FaqsCategory />} />
        </Route>
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="/changepassword"
          element={
            <RequireAuth>
              <UpdatePassword />
            </RequireAuth>
          }
        />
        <Route
          path="/userprofile"
          element={
            <RequireAuth>
              <UserProfileDisplay />
            </RequireAuth>
          }
        />
        <Route
          path="/account"
          element={
            <RequireAuth>
              <Account />
            </RequireAuth>
          }
        />
        <Route
          path="/user"
          element={
            <RequireAuth>
              <UserDashboard />
            </RequireAuth>
          }
        />
        <Route path="*" element={<NothingMatch />} />
      </Routes>
    );
  };

  return (
    <Router>
      <ErrorBoundary>
        <Suspense
          fallback={
            <div className="d-flex justify-content-center align-content-center my-5">
              <div class="spinner-border text-secondary" role="status" />
            </div>
          }
        >
          <AuthNav />
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export default SiteRoutes;
