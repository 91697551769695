import React, { useState, useEffect, useContext } from "react";
import { Header } from "./";
import { Pagination } from "../components";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { DashboardMenu } from "../components";
import { SiteContext } from "../context";

const FaqsCategory = () => {
  let navigate = useNavigate();
  let location = useLocation();

  let cat = location.state;

  const { apiUrl, userData } = useContext(SiteContext);

  const [faqs, setFaqs] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [faqSelected, setFaqSelected] = useState("");
  const [showAns, setShowAns] = useState(false);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getFaqs();
  }, [page, limit, cat]);

  const getFaqs = () => {
    let formData = new FormData();

    formData.append("cat", cat);
    formData.append("page", page);
    formData.append("limit", limit);
    formData.append("action", "fetchfaqscat");

    fetch(`${apiUrl}/api/faqs.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setFaqs(res.rows);
            setTotal(res.count);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  const handleFaqSelected = (question) => {
    showAns && setShowAns(false);
    setFaqSelected(question);
    setShowAns(true);
  };

  return (
    <div>
      <div>
        <div className="container">
          <div className="my-2 d-flex justify-content-between">
            <div className="align-items-center">
              {msg !== "" ? (
                <div className="p-2">
                  <span className="p-2 border border-info text-dark">
                    {msg}
                  </span>
                </div>
              ) : null}
              {errorMsg !== "" ? (
                <div className="p-2">
                  <span className="p-2 border border-info text-dark">
                    {errorMsg}
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          {faqs.length > 0 && (
            <Pagination
              limit={limit}
              page={page}
              total={total}
              cangoBack={cangoBack}
              cangoForward={cangoForward}
              handleChange={handleChange}
              doNext={doNext}
              doPrevious={doPrevious}
            />
          )}

          {faqs &&
            faqs.map((faq, i) => {
              return (
                <div key={faq.faqid}>
                  <div className="bg-light h4 p-2">
                    <button
                      className="w-100 text-decoration-none border-0 bg-transparent"
                      onClick={() => handleFaqSelected(faq.faqid)}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="p-2">
                          Q. {faq.faqquestion} <br />
                          <span className="text-muted small float-start">
                            {faq.faqcat}
                          </span>
                        </div>
                        <div className="p-2">
                          {faqSelected === faq.faqid && showAns ? (
                            <i className="bi bi-dash"></i>
                          ) : (
                            <i className="bi bi-plus"></i>
                          )}
                        </div>
                      </div>
                      {faqSelected === faq.faqid && showAns && (
                        <div className="p-2 d-flex justify-content-start border-top">
                          <p className="text-left h5">A. {faq.faqanswer}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FaqsCategory;
