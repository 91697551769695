import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import {
  ImageDisplay,
  SubCategoryNewsDisplay,
  BannerSide,
  NavigationBar,
  BannerTop,
} from "../components";
import { Dropdown } from "react-bootstrap";
import Seo from "./Seo";
import moment from "moment";

const Jobs = () => {
  const {
    userData,
    apiUrl,
    siteconfig,
    handleCurrentPage,
    handleJobs,
    siteMaxWidth,
    isMobile,
  } = useContext(SiteContext);

  const navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    handleCurrentPage("jobs");
    getJobs();
  }, []);

  const getJobs = () => {
    setLoading(true);

    fetch(`${apiUrl}/api/jobs.php?action=fetch_jobs`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setJobs(res.rows);
          setLoading(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitaliseText = (text) => {
    if (text === "") {
      return text;
    }
    let firstString = text.charAt(0);
    return firstString.toUpperCase() + "" + text.slice(1);
  };

  return (
    <div>
      <Seo
        title={`Jobs | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />
      <div style={{ maxWidth: siteMaxWidth }} className="container">
        <NavigationBar section={"Jobs"} goback={false} />

        {loading && (
          <div className="d-flex justify-content-center align-content-center my-5">
            <div class="spinner-border text-secondary" role="status" />
          </div>
        )}

        {errorMsg !== "" && <div className="container">{errorMsg}</div>}

        <div className="container">
          <div className="row my-1">
            <div className="col-md-12">
              {jobs.length > 0 && (
                <div className="row">
                  {jobs.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          isMobile ? `col-md-4 py-1 my-1` : `col-md-4 py-2 my-2`
                        }
                      >
                        <Link
                          key={i}
                          className="text-decoration-none text-dark"
                          to={`/job/${item.job_titleslug}`}
                        >
                          {item.job_photo !== undefined &&
                            item.job_photo !== "" &&
                            item.job_photo !== null && (
                              <ImageDisplay
                                url={`${item.job_photo}`}
                                title={item.job_title}
                                category=""
                                height={210}
                              />
                            )}
                          <div
                            className={
                              isMobile ? "h5 py-2 border-bottom" : "h5 py-2"
                            }
                          >
                            {item.job_title} ({item.job_status})
                          </div>
                          <div>
                            {item.job_description.split(" ", 25).join(" ")}
                          </div>
                          <div>Location: {item.job_location}</div>
                          {moment(item.job_to).format("Do MMM YYYY") <
                            moment().format("Do MMM YYYY") && (
                            <div>
                              Open until:{" "}
                              {moment(item.job_to).format("Do MMM YYYY")}
                            </div>
                          )}
                          <div className="my-2">
                            <span className="rounded bg-dark text-white p-2 my-2">
                              Details
                            </span>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Jobs;
