import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .label("Username")
    .required()
    .min(4, "Must have at least 4 characters"),
  firstname: Yup.string()
    .label("First name")
    .required()
    .min(3, "Must have at least 3 characters"),
  lastname: Yup.string()
    .label("Last name")
    .required()
    .min(3, "Must have at least 3 characters"),
  phone: Yup.string()
    .label("Mobile phone")
    .required()
    .min(11, "Must have at least 11 characters")
    .max(14, "Must have a maximum of 14 characters"),
  email: Yup.string()
    .label("Email")
    .email("Enter a valid email")
    .required("Please enter a registered email"),
  address: Yup.string()
    .label("Address")
    .required()
    .min(7, "Address must have at least 5 characters "),
  password: Yup.string()
    .label("Password")
    .required()
    .min(5, "Password must have at least 5 characters "),
  password2: Yup.string()
    .oneOf([Yup.ref("password")], "Confirm Password must match Password")
    .required("Confirm Password is required"),
  agree: Yup.boolean().oneOf([true], "Please check the agreement"),
});

const SignupFormRegular = ({ handleSubmit }) => {
  const [errorMsg, setErrorMsg] = useState("");

  return (
    <div>
      <div className="container">
        <h3 className="my-2">Sign up</h3>

        {errorMsg !== "" ? (
          <span className="p-2 my-2 border border-danger text-danger">
            {errorMsg}
          </span>
        ) : null}

        <Formik
          initialValues={{
            username: "",
            firstname: "",
            lastname: "",
            phone: "",
            email: "",
            address: "",
            password: "",
            password2: "",
            isactive: false,
            agree: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, { setSubmitting, resetForm });
          }}
        >
          {({
            handleChange,
            values,
            handleSubmit,
            errors,
            isValid,
            isSubmitting,
            handleBlur,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} encype="multipart/form-data">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group my-2">
                    <Field
                      id="firstname"
                      name="firstname"
                      value={values.firstname}
                      onChangeText={handleChange("firstname")}
                      placeholder="First name"
                      placeholderTextColor="#999"
                      onBlur={handleBlur("firstname")}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    />
                    {touched.firstname && errors.firstname && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.firstname && errors.firstname}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-2">
                    <Field
                      id="lastname"
                      name="lastname"
                      value={values.lastname}
                      onChangeText={handleChange("lastname")}
                      placeholder="Last name"
                      placeholderTextColor="#999"
                      onBlur={handleBlur("lastname")}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    />
                    {touched.lastname && errors.lastname && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.lastname && errors.lastname}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <Field
                      id="email"
                      name="email"
                      value={values.email}
                      placeholder="Your email"
                      onBlur={handleBlur("email")}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    />

                    {touched.email && errors.email && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.email && errors.email}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <Field
                      id="phone"
                      name="phone"
                      value={values.phone}
                      placeholder="Phone"
                      onBlur={handleBlur("phone")}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    />

                    {touched.phone && errors.phone && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.phone && errors.phone}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <Field
                      as="textarea"
                      id="address"
                      name="address"
                      value={values.address}
                      placeholder="Address"
                      onBlur={handleBlur("address")}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    />
                    {touched.address && errors.address && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.address && errors.address}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-1">
                    <Field
                      id="username"
                      name="username"
                      value={values.username}
                      placeholder="Username"
                      onBlur={handleBlur("username")}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    />
                    {touched.username && errors.username && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.username && errors.username}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      value={values.password}
                      placeholder="Password"
                      onBlur={handleBlur("password")}
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    />

                    {touched.password && errors.password && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.password && errors.password}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <Field
                      type="password"
                      id="password2"
                      name="password2"
                      value={values.password2}
                      placeholder="Confirm Password"
                      onBlur={handleBlur("password2")}
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    />

                    {touched.password2 && errors.password2 && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.password2 && errors.password2}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="agree"> Terms of service</label>
                    </div>
                    <Field type="checkbox" name="agree" />{" "}
                    {values.agree ? "I agree" : ""}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                buttonType="outline"
                onClick={handleSubmit}
                title="Create Account"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                className="btn btn-primary"
              >
                Submit
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignupFormRegular;
