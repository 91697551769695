import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import { TableProductCat, DashboardMenu, NavigationBar } from "../components";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import { AddProductCatModal, UpdateProductCatModal } from "../modals";
import { SiteContext } from "../context";

const AdminProductsCat = () => {
  const { apiUrl, slugify } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [items, setItems] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    getProductsCat();
  }, [items]);

  const getProductsCat = () => {
    fetch(`${apiUrl}/api/productcategory.php?action=fetch`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItems(res.categories);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal }) => {
    let action = values.id === "" ? "create" : "update";
    let formData = new FormData();

    formData.append("catname", values.catname);
    formData.append("catslug", slugify(values.catname));
    formData.append("catdesc", values.catdesc);
    formData.append("picture", values.picture);
    formData.append("cat", values.id);
    formData.append("action", action);

    const url =
      values.id === ""
        ? `${apiUrl}/api/productcategory.php`
        : `${apiUrl}/api/productcategory.php?id=${values.id}`;
    const sendType = "post";

    return fetch(url, {
      method: sendType,
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setShowModal(false);
          getProductsCat();
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setSubmitting(false);
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        } else {
          alert(
            "Failure",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleAdd = () => {
    setShowModal(true);
  };

  const handleUpdate = (item) => {
    setUpdateItem(item);
    setShowUpdateModal(true);
  };

  const doDelete = (id) => {
    let formData = new FormData();

    formData.append("cat", id);
    formData.append("role", userData.role);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/productcategory.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          getProductsCat();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <AddProductCatModal
        handleSubmit={handleSubmit}
        showModal={showModal}
        setShowModal={setShowModal}
        errorMsg={errorMsg}
      />
      <UpdateProductCatModal
        updateItem={updateItem}
        handleSubmit={handleSubmit}
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        errorMsg={errorMsg}
      />
      <Header />
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar section={"Products"} goback={true} />

              <h3>
                Product Categories | <Link to="/adminproducts">Products</Link>
              </h3>

              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    New category
                  </button>
                </div>
              </div>

              <TableProductCat
                items={items}
                onDelete={doDelete}
                handleUpdate={handleUpdate}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminProductsCat;
