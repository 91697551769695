import React, { useState, useContext } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .label("Username")
    .required()
    .min(4, "Must have at least 4 characters"),
  firstname: Yup.string()
    .label("First name")
    .required()
    .min(3, "Must have at least 3 characters"),
  lastname: Yup.string()
    .label("Last name")
    .required()
    .min(3, "Must have at least 3 characters"),
  phone: Yup.string()
    .label("Mobile phone")
    .required()
    .min(11, "Must have at least 11 characters")
    .max(14, "Must have a maximum of 14 characters"),
  email: Yup.string()
    .label("Email")
    .email("Enter a valid email")
    .required("Please enter a registered email"),
  address: Yup.string()
    .label("Address")
    .required()
    .min(5, "Address must have at least 5 characters "),
});

const arrayrange = (start, end) => {
  let myArray = [];
  for (let i = start; i <= end; i++) {
    myArray.push(i);
  }
  return myArray;
};

const UpdateSignupForm = ({ handleSubmit, updateItem, setShowModal }) => {
  const { apiUrl } = useContext(SiteContext);

  const [errorMsg, setErrorMsg] = useState("");

  return (
    <div>
      <div className="container">
        {errorMsg !== "" ? (
          <span className="p-2 my-2 border border-danger text-danger">
            {errorMsg}
          </span>
        ) : null}

        <Formik
          initialValues={{
            id: updateItem.id ? updateItem.id : "",
            username: updateItem.username ? updateItem.username : "",
            firstname: updateItem.firstname ? updateItem.firstname : "",
            lastname: updateItem.lastname ? updateItem.lastname : "",
            phone: updateItem.phone ? updateItem.phone : "",
            email: updateItem.email ? updateItem.email : "",
            address: updateItem.address ? updateItem.address : "",
            role: updateItem.role ? updateItem.role : "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, { setSubmitting, resetForm, setShowModal });
          }}
        >
          {({
            handleChange,
            values,
            handleSubmit,
            errors,
            isValid,
            isSubmitting,
            handleBlur,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} encype="multipart/form-data">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group my-2">
                    <div>
                      <label htmlFor="firstname">First name</label>
                    </div>
                    <Field
                      id="firstname"
                      name="firstname"
                      value={values.firstname}
                      onChangeText={handleChange("firstname")}
                      placeholder="First name"
                      placeholderTextColor="#999"
                      onBlur={handleBlur("firstname")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.firstname && errors.firstname && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.firstname && errors.firstname}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-2">
                    <div>
                      <label htmlFor="lastname">Last name</label>
                    </div>
                    <Field
                      id="lastname"
                      name="lastname"
                      value={values.lastname}
                      onChangeText={handleChange("lastname")}
                      placeholder="Last name"
                      placeholderTextColor="#999"
                      onBlur={handleBlur("lastname")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.lastname && errors.lastname && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.lastname && errors.lastname}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="email">Email</label>
                    </div>
                    <Field
                      id="email"
                      name="email"
                      value={values.email}
                      placeholder="Your email"
                      onBlur={handleBlur("email")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.email && errors.email && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.email && errors.email}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="phone">Phone</label>
                    </div>
                    <Field
                      disabled
                      id="phone"
                      name="phone"
                      value={values.phone}
                      placeholder="Phone"
                      onBlur={handleBlur("phone")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.phone && errors.phone && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.phone && errors.phone}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="username">Username</label>
                    </div>
                    <Field
                      disabled
                      id="username"
                      name="username"
                      value={values.username}
                      placeholder="Username"
                      onBlur={handleBlur("username")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.username && errors.username && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.username && errors.username}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="address">Address</label>
                    </div>
                    <Field
                      as="textarea"
                      id="address"
                      name="address"
                      value={values.address}
                      placeholder="Address"
                      onBlur={handleBlur("address")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.address && errors.address && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.address && errors.address}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                buttonType="outline"
                onClick={handleSubmit}
                title="Update"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                className="btn btn-primary"
              >
                Update
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateSignupForm;
