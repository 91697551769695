import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import { ImageDisplay, BannerSide, BannerTop } from "../components";
import { Dropdown } from "react-bootstrap";
import Seo from "./Seo";

const NewsSubCategory = () => {
  const {
    userData,
    apiUrl,
    siteconfig,
    allNews,
    isLoggedOn,
    categories,
    pages,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const { sid, section, id, title } = useParams();

  const navigate = useNavigate();

  const [animationPhotos, setAnimationPhotos] = useState([]);
  const [stories, setStories] = useState([]);
  const [news, setNews] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    handleCurrentPage(title);
    getSubCategoryNews();
    getSubCat();
  }, [id, sid]);

  const getSubCategoryNews = () => {
    if (sid === "" || sid === undefined) {
      setErrorMsg("Error retrieving news items for " + title);
      return;
    }
    setLoading(true);

    fetch(`${apiUrl}/api/fetch_sub_category_news.php?action=fetch&id=${sid}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setNews(res.news);
          setLoading(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubCat = () => {
    fetch(`${apiUrl}/api/fetch_subcategories.php?action=fetch&id=${id}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setSubCategories(res.subcategories);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitaliseText = (text) => {
    if (text === "") {
      return "";
    }
    let firstString = text.charAt(0);
    return firstString.toUpperCase() + "" + text.split("-").join(" ").slice(1);
  };

  return (
    <div>
      <Seo
        title={`${section} | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />
      <div style={{ backgroundColor: "#ff0000" }} className="p-2">
        <div className="container h1 text-light p-2">
          {capitaliseText(section)} | {capitaliseText(title)}
        </div>
        <div className="container d-flex align-items-center justify-content-between">
          <div className="inline-block">
            {isMobile
              ? subCategories.slice(0, 3).map((item, i) => {
                  return (
                    <Link
                      key={i}
                      to={`/sub-category/${id}/${section}/${
                        item.nsubcatid
                      }/${item.nsubcat.toLowerCase().split(" ").join("-")}`}
                      className="text-white px-2 text-decoration-none"
                    >
                      {item.nsubcat}
                    </Link>
                  );
                })
              : subCategories.map((item, i) => {
                  return (
                    <Link
                      key={i}
                      to={`/sub-category/${id}/${section}/${
                        item.nsubcatid
                      }/${item.nsubcat.toLowerCase().split(" ").join("-")}`}
                      className="text-white px-2 text-decoration-none"
                    >
                      {item.nsubcat}
                    </Link>
                  );
                })}
          </div>
          {subCategories.length > 3 && isMobile && (
            <div className="inline-block">
              <Dropdown>
                <Dropdown.Toggle
                  className="btn border-0"
                  style={{ color: "white" }}
                  variant="transparent"
                  id="none"
                >
                  <span className="text-white small py-0">
                    <i className="bi bi-list"></i> More
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ backgroundColor: "#ff0000" }}>
                  {subCategories.slice(3).map((item, i) => {
                    return (
                      <Dropdown.Item>
                        <Link
                          key={i}
                          to={`/sub-category/${id}/${section}/${
                            item.nsubcatid
                          }/${item.nsubcat.toLowerCase().split(" ").join("-")}`}
                          className="text-white px-2 text-decoration-none"
                        >
                          {item.nsubcat}
                        </Link>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-content-center my-5">
          <div class="spinner-border text-secondary" role="status" />
        </div>
      )}

      {errorMsg !== "" && <div className="container">{errorMsg}</div>}

      <div className="container py-3 my-1">
        <div className="row">
          <div className="col-md-6 p-2">
            {news.length > 0 &&
              news.slice(0, 1).map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={`/${item.newscatid}/${section.toLowerCase()}/${
                      item.newsid
                    }/${item.ntitle_slug}`}
                  >
                    <ImageDisplay
                      url={`${item.nphoto}`}
                      title={item.ntitle}
                      showText
                      largeimg
                      category={item.ncat}
                      height={isMobile ? 280 : 420}
                    />
                  </Link>
                );
              })}
          </div>
          <div className="col-md-6">
            <div className="row">
              {news.length > 0 &&
                news.slice(1, 5).map((item, i) => {
                  return (
                    <div className="col-md-6 p-1">
                      <Link
                        key={i}
                        to={`/${item.newscatid}/${section.toLowerCase()}/${
                          item.newsid
                        }/${item.ntitle_slug}`}
                      >
                        <ImageDisplay
                          url={`${item.nphoto}`}
                          title={item.ntitle}
                          showText
                          height={210}
                        />
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <BannerTop />

      {news.length > 0 && (
        <div className="container">
          <div className="row my-1">
            <div className="col-md-9">
              <div className="row">
                {news.slice(5, 35).map((item, i) => {
                  return (
                    <div className={`col-md-4`}>
                      <Link
                        key={i}
                        className="text-decoration-none text-dark"
                        to={`/${item.newscatid}/${item.ncat.toLowerCase()}/${
                          item.newsid
                        }/${item.ntitle_slug}`}
                      >
                        {!isMobile && (
                          <ImageDisplay
                            url={`${item.nphoto}`}
                            title={item.ntitle}
                            category={item.ncat}
                            height={210}
                          />
                        )}
                        <div
                          className={
                            isMobile ? "h5 py-2 border-bottom" : "h5 py-2"
                          }
                        >
                          {item.ntitle}
                        </div>
                        {!isMobile && (
                          <div>{item.nbrief.split(" ", 10).join(" ")}</div>
                        )}
                      </Link>
                    </div>
                  );
                })}
              </div>
              <BannerTop />
            </div>
            <div className="col-md-3">
              <BannerSide />
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default NewsSubCategory;
