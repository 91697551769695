import React from "react";
import { Header, Footer } from "./";
import { CategoriesFaqsMenu } from "../components";
import { Outlet } from "react-router-dom";

const FaqsList = () => {
  return (
    <div>
      <Header />
      <div className="container my-2 py-2">
        <h3>Frequently Asked Questions</h3>

        <div className="row my-2">
          <div className="col-md-3 order-2 order-md-1">
            <CategoriesFaqsMenu />
          </div>
          <div className="col-md-9 my-3 order-1 order-md-2">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FaqsList;
