import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "../screens";
import { SiteContext } from "../context";
import { DoQuantity } from "./";
import { useNavigate, Link } from "react-router-dom";

const Cart = ({ section }) => {
  const navigate = useNavigate();

  const {
    apiUrl,
    products,
    isMobile,
    formatAmount,
    cart,
    manageCart,
    manageCartNew,
    singleProduct,
  } = useContext(SiteContext);

  const [canOrder, setCanOrder] = useState(false);

  const getOrderTotal = () => {
    const cartItems = cart.slice();
    const total = cartItems.reduce((a, b) => a + parseFloat(b.total), 0);

    if (total > 0) {
      return parseFloat(total).toFixed(2);
    } else {
      return 0.0;
    }
  };

  const getItemTotal = (id) => {
    const cartItems = cart.slice();
    const item = cartItems.filter((a) => a.id == id);

    if (item.length > 0) {
      return item[0].qty;
    } else {
      return "0";
    }
  };

  const getProductImg = (id) => {
    let filteredProduct = products.filter((item) => item.id === id);

    return filteredProduct[0].picture;
  };

  const confirmDelete = (product, item) => {
    let text = "Confirm deletion of " + product + " from cart!";
    if (window.confirm(text) == true) {
      manageCart("delete", item);
    }
  };

  return (
    <div>
      <Header />

      <div className={isMobile ? "container" : "w-75 mx-auto"}>
        <div className="container mt-3 px-4 py-2 bg-light">
          <Link className="text-decoration-none" to="/">
            Home
          </Link>{" "}
          /{" "}
          <Link className="text-decoration-none" to="/products">
            Products
          </Link>{" "}
          / <span>Cart</span>
        </div>
        <div className="my-2 py-1">
          <h4>Cart</h4>

          {cart.length > 0 ? (
            <div>
              {cart.map((item, i) => {
                return (
                  <div key={i}>
                    <div className="row border border-light bg-light rounded py-2">
                      <div className="col-2">
                        <img
                          src={`${apiUrl}/${getProductImg(item.id)}`}
                          height={60}
                          width={60}
                          alt={item.item}
                        />
                      </div>
                      <div className="col-4">
                        <Link
                          to={`/products/${item.item
                            .toLowerCase()
                            .split(" ")
                            .join("-")}`}
                          className="text-decoration-none"
                        >
                          {item.item}
                        </Link>{" "}
                        <br />
                        <span className="text-muted">
                          {" "}
                          ({item.price !== 0 && formatAmount(item.price)})
                        </span>
                      </div>
                      <div className="col-3 small">
                        <DoQuantity itemid={item.id} />
                      </div>

                      <div className="col-3 text-end">
                        <span className="small">
                          {item.total !== 0 && formatAmount(item.total)}
                        </span>
                        <div
                          className="cursor-pointer"
                          onClick={() => confirmDelete(item.item, item.id)}
                        >
                          <i className="bi bi-trash text-danger h4"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="my-2 py-2 border-top border-dark d-flex justify-content-between">
                <span className="h6">Total</span>
                <span className="h6 text-end">
                  {formatAmount(getOrderTotal())}
                </span>
              </div>
              <div className="py-2 d-flex justify-content-between">
                <button
                  className="px-2 py-1 h6 text-white bg-danger rounded"
                  onClick={() => manageCart("clear")}
                >
                  Empty your cart
                </button>
                {section !== "checkout" && (
                  <button
                    className="px-3 py-2 h6 text-white bg-dark rounded"
                    onClick={() => navigate("/checkout")}
                  >
                    Checkout
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div>
              <p>Your cart is empty</p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cart;
