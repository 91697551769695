import React, { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Header, Footer } from "./";
import { SignupFormRegular } from "../components";
import { SiteContext } from "../context";

const Signup = () => {
  const [duplicates, setDuplicates] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [msg, setMsg] = useState("");

  const { apiUrl } = useContext(SiteContext);
  let navigate = useNavigate();

  const checkUnique = (email, username, phone) => {
    setDuplicates([]);

    fetch(
      `${apiUrl}/api/users.php?action=checkunique&email=${email}&username=${username}&phone=${phone}`,
      {
        method: "get",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setDuplicates(res.msg);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    checkUnique(values.email, values.username, values.phone);

    setSubmitting(false);

    if (duplicates.length > 0) {
      return false;
    }

    let formData = new FormData();

    formData.append("email", values.email);
    formData.append("username", values.username);
    formData.append("fname", values.firstname);
    formData.append("lname", values.lastname);
    formData.append("phone", values.phone);
    formData.append("password", values.password);
    formData.append("isactive", values.isactive ? 1 : 0);
    formData.append("terms", values.agree ? 1 : 0);
    formData.append("address", values.address);
    formData.append("role", "customer");
    formData.append("action", "createuser");

    return fetch(`${apiUrl}/api/users.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);
        await localStorage.clear();

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
            navigate("/login", { state: "Registration successful." });
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Signup failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div>
      <Header />
      <div className="container">
        {msg !== "" ? (
          <div className="my-4">
            <span className="p-2 my-2 border border-info text-dark">{msg}</span>
          </div>
        ) : null}
        {errorMsg !== "" ? (
          <div className="my-4">
            <span className="p-2 my-2 border border-danger text-danger">
              {errorMsg}
            </span>
          </div>
        ) : null}

        {duplicates.length > 0 && (
          <p className="text-danger my-2 p-1 border border-danger">
            {duplicates.map((item, i) => (
              <div key={i}>{item}</div>
            ))}
          </p>
        )}
        <SignupFormRegular handleSubmit={handleSubmit} />

        <p className="my-3">
          <Link className="my-3 py-2 text-decoration-none h4" to="/login">
            Login
          </Link>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
