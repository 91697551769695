import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { SiteContext } from "../context";

const CategoriesFaqsMenu = () => {
  let navigate = useNavigate();

  const { apiUrl, slugify } = useContext(SiteContext);
  const [categories, setCategories] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getFaqsCat();
  }, []);

  const getFaqsCat = () => {
    fetch(`${apiUrl}/api/faqs.php?action=fetchcat`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setCategories(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container my-3 py-3">
      <h4 className="bg-dark text-white px-3 py-2">Categories</h4>

      {categories.length > 0 && (
        <div>
          <div
            className="border border-light px-3 py-2"
            style={{ backgroundColor: "#5B98C3" }}
          >
            <Link className="text-decoration-none text-white h6" to={`/faqs`}>
              All FAQs
            </Link>
          </div>
          {categories.map((item, i) => {
            return (
              <div key={i}>
                <div
                  className="border border-light px-3 py-2"
                  style={{ backgroundColor: "#5B98C3" }}
                >
                  <Link
                    className="text-decoration-none w-100 text-white h6"
                    to={`category/${slugify(item.faqcat)}`}
                    state={item.faqcat}
                  >
                    {item.faqcat}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CategoriesFaqsMenu;
